import type React from 'react'

import { WEB_CONTAINER_VERTICAL_SPACE } from '@knauf-group/ct-designs/utils/constants'
import { useBreakpoints } from '@knauf-group/ct-designs/utils/hooks/useBreakpoints'
import type { Styles } from '@knauf-group/ct-designs/utils/types'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'

import type { ImageGalleryTextComboProps } from '.'
import ImageGallery from './ImageGallery'

const ImageGalleryTextCombo: React.FC<ImageGalleryTextComboProps> = ({
  images,
  isProductImage,
  children,
  dataCy = 'image-gallery-text-combo',
}) => {
  const theme = useTheme()
  const { isMobile } = useBreakpoints()
  const styles: Styles = {
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      '& h2': {
        margin: 0,
      },
      '& h4': {
        ...theme.typography.h4,
        color: theme.palette.text.primary,
        margin: 0,
      },
      '& p': {
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        marginBottom: 0,
      },
    },
  }

  return (
    <Container
      sx={WEB_CONTAINER_VERTICAL_SPACE}
      maxWidth={isMobile ? false : undefined}
      disableGutters={isMobile ? true : undefined}
    >
      <Grid container sx={styles.container} data-cy={dataCy} spacing={theme.spacing(3)}>
        <Grid item xs={12} md={6}>
          <ImageGallery images={images} isProductImage={isProductImage} />
        </Grid>
        <Grid item xs={12} md={6} sx={styles.textWrapper} data-cy={`${dataCy}-text-wrapper`}>
          {isMobile ? <Container sx={styles.textWrapper}>{children}</Container> : children}
        </Grid>
      </Grid>
    </Container>
  )
}

export default ImageGalleryTextCombo
