import type React from 'react'
import Image from 'next/image'

import useDevicePixelRatio from '@cms/hooks/useDevicePixelRatio'
import { SMART_CROP_TOKENS } from '@cms/utils/constants'
import {
  getDevicePixelRatioPriority,
  getRenditionImageLoader,
  getSmartCropImageLoaderByTokenName,
} from '@cms/utils/utils'
import type { Steps } from '@knauf-group/ct-designs/components/core/Carousel'
import { ImageGalleryDesktop } from '@knauf-group/ct-designs/components/core/ImageGallery/ImageGalleryDesktop/ImageGalleryDesktop'
import { ImageGalleryMobile } from '@knauf-group/ct-designs/components/core/ImageGallery/ImageGalleryMobile/ImageGalleryMobile'
import {
  ImageWrapper,
  type WithNextImageComponent,
} from '@knauf-group/ct-designs/components/core/ImageWrapper'
import { ScreenSM } from '@knauf-group/ct-designs/utils/hooks/mediaQuery'
import type { ImageWeb, QaHook, Styles } from '@knauf-group/ct-designs/utils/types'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

export type ImageGalleryProps = {
  images?: ImageWeb[]
} & Steps &
  Omit<WithNextImageComponent, 'imageLoader' | 'nextImageComponent'> &
  QaHook

const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  isProductImage = false,
  dataCy = 'image-gallery',
}) => {
  const theme = useTheme()
  const { devicePixelRatio } = useDevicePixelRatio()
  const isScreenSm = ScreenSM(theme)

  const styles: Styles = {
    container: {
      width: '100%',
    },
    imageMain: {
      height: '100%',
      objectFit: isProductImage ? 'contain' : 'cover',
    },
  }

  const devicePixelRatioPriority = getDevicePixelRatioPriority(devicePixelRatio)

  // Loader for the Thumbnail images(small ones in the bottom list of the component)
  const imageLoaderThumbnail = getSmartCropImageLoaderByTokenName(
    SMART_CROP_TOKENS.CF_16X9_S,
    devicePixelRatioPriority,
  )

  // Loader for the Main image(big one)
  const imageLoaderMain = isProductImage
    ? getRenditionImageLoader(devicePixelRatioPriority)
    : getSmartCropImageLoaderByTokenName(SMART_CROP_TOKENS.CF_16X9_L, devicePixelRatioPriority)

  const imageSlides = images?.map((image, index) => (
    <ImageWrapper
      image={image}
      nextImageComponent={Image}
      // empty loader triggers the default one which applies the Optimization API which serves them directly from the app. See https://nextjs.org/docs/pages/building-your-application/optimizing/images#loaders
      loader={imageLoaderMain}
      sx={styles.imageMain}
      data-cy={`${dataCy}-main-image`}
      key={`main-image-${image.title}-${index}` as React.Key}
      // Due to issues with lazy loading with RTL in chrome, this is a workaround. See https://github.com/davidjerleke/embla-carousel/discussions/736#discussioncomment-8476971
      priority={theme.direction === 'rtl'}
    />
  ))

  const thumbnailSlides = images?.map((image, index) => (
    <ImageWrapper
      image={image}
      nextImageComponent={Image}
      loader={imageLoaderThumbnail}
      data-cy={`${dataCy}-thumbnail`}
      key={`thumbnail-image-${image.title}-${index}` as React.Key}
    />
  ))

  return (
    <Box sx={styles.container} data-cy={dataCy}>
      {images?.length > 0 &&
        (isScreenSm ? (
          <ImageGalleryMobile isEnlargedViewEnabled slides={imageSlides} />
        ) : (
          <ImageGalleryDesktop
            isEnlargedViewEnabled
            mainSlides={imageSlides}
            thumbnailSlides={thumbnailSlides}
          />
        ))}
    </Box>
  )
}

export default ImageGallery
