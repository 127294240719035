import type React from 'react'

import { FALLBACK_IMAGES } from '@cms/utils/constants'
import { getImagesWithFallback } from '@cms/utils/utils'
import { ImageGalleryTextCombo } from '@components/ImageGalleryTextCombo'
import type { ImageWeb } from '@knauf-group/ct-designs/utils/types'

import RichText from '../RichText'
import type { ImageGalleryTextComboWrapperProps } from './ImageGalleryTextComboWrapper.types'

const ImageGalleryTextComboWrapper: React.FC<ImageGalleryTextComboWrapperProps> = ({
  dataCy = 'image-gallery-text-combo',
  ...props
}) => {
  const isProductImage = Boolean(props?.fields?.productImage)
  const images = props.fields?.images as ImageWeb[]

  const imagesFormatted = getImagesWithFallback(images, FALLBACK_IMAGES.DEFAULT_16_9_RATIO)

  return (
    <ImageGalleryTextCombo
      isProductImage={isProductImage}
      images={imagesFormatted}
      dataCy={dataCy}
    >
      <RichText richTextDocument={props.fields?.text} dataCy={'image-gallery'} />
    </ImageGalleryTextCombo>
  )
}

export default ImageGalleryTextComboWrapper
